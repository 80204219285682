import React, { useMemo, useState, useCallback } from 'react';
import { useField } from 'formik';
import classnames from 'classnames';
import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';
// import 'react-phone-input-2/lib/material.css';

import * as styles from './input-phone.module.scss';
import './flags.scss';

const InputPhone = ({
  // name,
  className,
  label,
  requiredField = false,
  isDisabled = false,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [isFocused, setIsFocused] = useState(false);

  const statusCssClasses = useMemo(
    () => ({
      [styles.error]: meta.touched && meta.error,
      [styles.success]: meta.touched && !meta.error,
      [styles.focused]: isFocused,
      [styles.disabled]: isDisabled,
    }),
    [meta, isFocused, isDisabled]
  );

  const focusHandler = useCallback(() => {
    setIsFocused(true);
  }, [setIsFocused]);

  const blurHandler = useCallback(
    (e) => {
      setIsFocused(false);
      field.onBlur(e);
    },
    [setIsFocused, field]
  );

  const handleChange = useCallback(
    (e, number) => {
      field.onChange({
        target: {
          name: e.target.name,
          value: `+${number}`,
        },
      });
    },
    [field]
  );

  return (
    <div className={classnames(styles.input, className, statusCssClasses)}>
      <PhoneInput
        country={'ch'}
        preferredCountries={['ch', 'de', 'nl', 'us', 'hr']}
        excludeCountries={[
          'ao',
          'aw',
          'io',
          'bq',
          'ci',
          'cw',
          'gf',
          'pf',
          'gp',
          'gu',
          'mh',
          'nc',
          'ps',
          're',
          'lc',
        ]}
        enableSearch={true}
        specialLabel={
          label ? (
            <label
              htmlFor={`input-${props.name}`}
              className={classnames(
                styles.input_label,
                'scr-txt-up',
                'scr-small'
              )}
            >
              {label} {requiredField && <span className="scr-red">*</span>}
            </label>
          ) : (
            ''
          )
        }
        inputProps={{
          name: props.name,
          required: requiredField,
        }}
        searchPlaceholder={'Search country'}
        containerClass={styles.input_container}
        inputClass={styles.input_elem}
        buttonClass={styles.input_button}
        dropdownClass={styles.input_dropdown}
        searchClass={styles.input_search}
        disableSearchIcon={true}
        {...field}
        {...props}
        onChange={(number, country, e) => {
          handleChange(e, number, country);
        }}
        onFocus={focusHandler}
        onBlur={blurHandler}
      />

      {meta.touched && meta.error && (
        <div className={classnames('scr-small', 'scr-red', styles.input_error)}>
          {meta.error}
        </div>
      )}
    </div>
  );
};

export default InputPhone;
