import EmailJSSettings from '../constants/emailjs';

const {
  url,
  service_id,
  templates,
  user_id,
} = EmailJSSettings;

const createRequestOptions = (data, template) => {
  let formData = new FormData();

  formData.append('service_id', service_id);
  formData.append('template_id', templates[template]);
  formData.append('user_id', user_id);

  Object.keys(data).forEach(key => {
    formData.append(key, data[key]);
  });

  return {
    url,
    method: 'POST',
    data: formData
  }
}

export default createRequestOptions;