const EmailJSSettings = {
  url: 'https://api.emailjs.com/api/v1.0/email/send-form',
  service_id: 'service_298hllj',
  user_id: 'user_8CAce8UyDe7GpVdwbhUVl',
  templates: {
    action_form: 'template_v2ufl88',
    lets_talk_form: 'template_x9eqync',
    webinar_form: 'template_mtxb5dr',
  }
}

export default EmailJSSettings;
